.container-wrapper {
    width: 100%;
}

.light.container-wrapper {
    background-color: white;
}

.dark.container-wrapper {
    background-color: #364d79;
}

.medium.container-wrapper {
    background-color: whitesmoke;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: auto;
    overflow: hidden;
}

@media only screen and (min-width: 480px) {
    .small-container {
        width: 432px;
        margin: 0 auto;
    }
    .small-container .page {
        margin: 24px auto;
    }
}

@media only screen and (min-width: 992px) {
    .container {
        width: 944px;
        margin: 0 auto;
    }
    .container .page {
        margin: 24px auto;
    }
}
